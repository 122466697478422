@import '../../styles/colors.scss';

.tabs {
  nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    section {
      height: 30px;
      padding: 0 20px 0 0;
      font-size: 0.9em;
      cursor: pointer;

      &.active {
        color: $color7;
        border-bottom: 1px solid $color7;
        font-weight: bold;
      }
    }
  }
}