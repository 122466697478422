$color0: #000000;
$color1: #FFFFFF;
$color2: #EE4540;
$color3: #44444D;
$color4: #768495;
$color5: #F3F5F7;
$color6: #D6DAE2;
$color7: #E73934;
$color8: #E9E9E9;
$color9: #FAFAFA;
$color10: #FCECEB;
