@import '../../styles/colors.scss';

.input {
  margin: 0 0 20px;
  width: 320px;
  height: 26px;
  border: 0.5px solid $color6;
  border-radius: 45px;
  text-indent: 10px;
  color: $color3;
  font-weight: 200;
  outline: 0;
  box-sizing: border-box;
  background: transparent;
  font-size: 0.8em;
  
  &.big {
    height: 45px;
    text-indent: 20px;
    font-size: 1em;
  }

  &.invalid {
    border-color: $color7;
    color: $color7;
  }

  &:disabled {
    background: $color5;
    cursor: not-allowed;
  }
}
