.field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px 10px;
  align-self: flex-end;

  .label {
    margin: 0 10px 0 0;
    font-size: 0.9em;
  }

  .input {
    margin: 0;
  }
}
