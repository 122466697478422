@import '../../styles/colors.scss';
@import '../../styles/animations.scss';

.searchable-multi-select {
  position: relative;
  display: flex;
  flex: auto 0 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0;

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .selected {
    pointer-events: none;
    background: $color5;
    border-color: $color5;
    animation: lazy 0.8s linear 0s infinite alternate;
    color: transparent;
  }
  
  > .selected {
    position: relative;
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 0.8em;
    height: 100%;
    padding: 0 13px 0 16px;
    align-items: center;
    height: 26px;
    border-radius: 25px;
    border: 0.5px solid $color4;
    box-sizing: border-box;
    outline: 0;
    width: auto;

    .label {
      text-indent: 8px;
      font-weight: 200;
      color: $color3;
    }

    .amount {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: $color4;
      color: $color1;
      position: absolute;
      top: 2px;
      left: 12px;
      font-size: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    > * {
      pointer-events: none;
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $color0;
      margin: 0 0 0 10px;
    }
  }

  &.open .options-container {
    display: block;
  }

  .options-container {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    background: white;
    box-shadow: 0 2px 8px 0 $color6;
    border-radius: 5px;
    z-index: 100;
    min-width: max-content;
    width: 100%;

    > input.search {
      width: 100%;
      height: 26px;
      font-size: 0.8em;
      border: 0.5px solid #768495;
      border-radius: 45px;
      text-indent: 10px;
      color: #44444D;
      font-weight: 200;
      outline: 0;
      box-sizing: border-box;
      background: transparent;
      margin-bottom: 2px;
    }

    > ul.options {
      width: auto;
      max-height: 50vh;
      overflow-y: auto;
      position: relative;
  
      .option {
        display: flex;
        height: 25px;
        padding: 0 10px;
        align-items: center;
        cursor: pointer;
        color: $color3;
        font-size: 0.8em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
  
        &.selected, &:hover {
          background: $color5;
        }
  
        .checkbox {
          margin: 0;
        }
  
        .label {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}
