@import '../../styles/colors.scss';

.tabs {
  .content {
    > .filters {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 15px;
    }
  }

  > .options {
    display: flex;
    flex-direction: row;
    border-bottom: 0.25px solid $color4;
    margin: 0 0 15px;
    
    .option {
      height: 30px;
      padding: 0 50px 0 0;
      font-size: 0.9em;
      cursor: pointer;

      &.active {
        color: $color7;
        border-bottom: 1px solid $color7;
        font-weight: bold;
      }
    }
  }
}