@import '../styles/colors.scss';

.menu {
  display: flex;
  flex-direction: column;
  flex: auto 0;
  width: 185px;
  background: $color3;
  padding: 30px 0;
  flex-shrink: 0;

  .logo {
    width: 132px;
    margin: 0 auto 72px;
  }
}
