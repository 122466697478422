@import '../../styles/colors.scss';

.page.forgot-password {
  flex-direction: row;

  .title {
    font-size: 2em;
    margin: 0 0 27px;
  }

  .logo {
    width: 275px;
  }

  .input {
    height: 45px;
    text-indent: 23px;
    border-color: $color4;

    &.email {
      margin: 0 0 27px;
    }
  }

  .button {
    box-shadow: none;
    margin: 0 0 10px;
  }
}
