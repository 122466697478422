@import '../../styles/colors.scss';
@import '../../styles/animations.scss';

.button {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 0 25px;
  margin: 0;
  background: $color4;
  color: white;
  border: none;
  border-radius: 35px;
  font-size: 0.7em;
  outline: 0;
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgba(68,68,77,0.2);

  &.big {
    height: 33px;
    background: $color2;
    font-size: 1em;
    margin: 0 0 15px;
  }

  &.big.loading {
    position: relative;
    width: 33px;
    padding: 0;
    color: transparent;
    background-color: transparent;
    border: 3px solid transparent;
    border-right: 3px solid $color2;
    animation: 1s spin infinite;
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
