@charset "UTF-8";
.pagination {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 22px; }
  .pagination .button {
    width: 20px;
    height: 20px;
    color: #44444D;
    background: #FAFAFA;
    padding: 0;
    margin: 0 0 0 5px; }
    .pagination .button.active {
      background: #E73934;
      color: #FFFFFF; }
  .pagination .separator {
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination .separator::before {
      content: '···'; }
