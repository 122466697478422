@import '../../styles/colors.scss';

.table-actions {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .separator {
    margin: 0 10px;
    width: 1px;
    height: 20px;
    background: $color6;
  }

  .icon {
    cursor: pointer;
  }
}