.modal {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;

  .title {
    font-size: 1.7em;
    border-bottom: 0.5px solid #44444D;
    padding: 0 0 5px;
  }

  .frame {
    background: white;
    width: 500px;
    border-radius: 10px;
    padding: 20px;
  }

  &.draft-report-modal {
    .frame {
      width: 680px;

      .fields {
        align-items: flex-start;
        justify-content: flex-start;
  
        .field {
          flex-direction: row;
          align-items: center;
          align-self: initial;
          margin: 0 0 10px;

          &:first-child {
            margin-right: 20px;
          }

          > .label {
            min-width: 44px;
          }

          > .input.date-input {
            width: auto;
          }
        }
      }
    }
  }

  &.print-modal {
    .frame {
      width: 300px;

      .fields {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .field {
          flex: 0;
          flex-basis: 100px;
          cursor: pointer;
          height: 25px;
          margin: 0;
          margin-bottom: 10px;

          input {
            margin-right: 4px;
          }

          label {
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
