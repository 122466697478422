@import '../../styles/colors.scss';

.list {
  width: 100%;

  header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      margin: 0 10px 10px 0;
      flex: 1; 

      &:last-of-type {
        flex: 0;
        flex-basis: 12px;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      > div {
        flex: 1;
        margin-right: 10px;

        &:last-of-type {
          flex: 0;
        }
      }

      .input,
      .searchable-select {
        width: 100% !important;
        flex: 1;
        margin: 0;
      }

      .input.date {
        width: auto !important;
      }

      input:invalid {
        border-color: $color7;
      }
    }

    > .icon {
      margin-top: 5px;
      width: 26px;
      height: 26px;
    }
    
    .icon {
      cursor: pointer;
    }
  }

  &.time-records {
    header {
      p:first-child,
      p:nth-child(2) {
        flex: 2;
      }

      p:nth-child(3),
      p:nth-child(4) {
        flex: 0;
        flex-basis: 75px;
      }
    }

    ul {
      li {
        > div:first-child,
        > div:nth-child(2) {
          flex: 2;
        }

        > div:nth-child(3),
        > div:nth-child(4) {
          flex: 0;
          flex-basis: 75px;
        }
      }
    }
  }

  &.material-records {
    header {
      p:nth-child(2) {
        flex: 0;
        text-align: center;
      }
    }

    ul {
      li {
        > div:nth-child(2) {
          flex: 0;
          flex-basis: 55px;
        }
      }
    }
  }
}