@import '../../styles/colors.scss';

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  color: $color2;
  background: $color10;
  height: 27px;
  border-radius: 27px;
  padding: 0 20px;
  margin: 0 0 20px;
}
