.input {
  margin: 0 0 20px;
  width: 320px;
  height: 26px;
  border: 0.5px solid #D6DAE2;
  border-radius: 45px;
  text-indent: 10px;
  color: #44444D;
  font-weight: 200;
  outline: 0;
  box-sizing: border-box;
  background: transparent;
  font-size: 0.8em; }
  .input.big {
    height: 45px;
    text-indent: 20px;
    font-size: 1em; }
  .input.invalid {
    border-color: #E73934;
    color: #E73934; }
  .input:disabled {
    background: #F3F5F7;
    cursor: not-allowed; }

@-webkit-keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.button {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 0 25px;
  margin: 0;
  background: #768495;
  color: white;
  border: none;
  border-radius: 35px;
  font-size: 0.7em;
  outline: 0;
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgba(68, 68, 77, 0.2); }
  .button.big {
    height: 33px;
    background: #EE4540;
    font-size: 1em;
    margin: 0 0 15px; }
  .button.big.loading {
    position: relative;
    width: 33px;
    padding: 0;
    color: transparent;
    background-color: transparent;
    border: 3px solid transparent;
    border-right: 3px solid #EE4540;
    -webkit-animation: 1s spin infinite;
            animation: 1s spin infinite;
    box-shadow: none; }
  .button:disabled {
    opacity: 0.5;
    pointer-events: none; }

.link {
  color: #44444D;
  font-size: 0.8em; }

.title {
  font-family: 'arciform';
  font-size: 2em;
  margin: 0 0 20px;
  padding: 0;
  font-weight: 500; }

.tabs .content > .filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px; }

.tabs > .options {
  display: flex;
  flex-direction: row;
  border-bottom: 0.25px solid #768495;
  margin: 0 0 15px; }
  .tabs > .options .option {
    height: 30px;
    padding: 0 50px 0 0;
    font-size: 0.9em;
    cursor: pointer; }
    .tabs > .options .option.active {
      color: #E73934;
      border-bottom: 1px solid #E73934;
      font-weight: bold; }

.field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px 10px;
  align-self: flex-end; }
  .field .label {
    margin: 0 10px 0 0;
    font-size: 0.9em; }
  .field .input {
    margin: 0; }

.toggle {
  display: flex;
  position: relative;
  width: 25px;
  height: 12px;
  background: #FFFFFF;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid #E9E9E9; }
  .toggle::after {
    content: '';
    position: absolute;
    width: 10px;
    border-radius: 9px;
    background: #E9E9E9;
    top: 1px;
    bottom: 1px;
    margin: auto;
    left: 1px; }
  .toggle.active::after {
    left: auto;
    right: 1px;
    background: #D6DAE2; }

@-webkit-keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.multi-select {
  position: relative;
  display: flex;
  flex: auto 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0; }
  .multi-select.disabled {
    cursor: not-allowed; }
  .multi-select.disabled .selected {
    pointer-events: none;
    background: #F3F5F7;
    border-color: #F3F5F7;
    -webkit-animation: lazy 0.8s linear 0s infinite alternate;
            animation: lazy 0.8s linear 0s infinite alternate;
    color: transparent; }
  .multi-select > .selected {
    position: relative;
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 0.8em;
    height: 100%;
    padding: 0 13px 0 16px;
    align-items: center;
    height: 26px;
    border-radius: 25px;
    border: 0.5px solid #768495;
    box-sizing: border-box;
    outline: 0;
    width: auto; }
    .multi-select > .selected .label {
      text-indent: 8px;
      font-weight: 200;
      color: #44444D; }
    .multi-select > .selected .amount {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #768495;
      color: #FFFFFF;
      position: absolute;
      top: 2px;
      left: 12px;
      font-size: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; }
    .multi-select > .selected > * {
      pointer-events: none; }
    .multi-select > .selected::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000000;
      margin: 0 0 0 10px; }
  .multi-select.open .options {
    display: block; }
  .multi-select .options {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    background: white;
    box-shadow: 0 2px 8px 0 #D6DAE2;
    border-radius: 5px;
    z-index: 100;
    width: 200px;
    max-height: 50vh;
    overflow-y: auto; }
    .multi-select .options .option {
      display: flex;
      height: 25px;
      padding: 0 10px;
      align-items: center;
      cursor: pointer;
      color: #44444D;
      font-size: 0.8em; }
      .multi-select .options .option.selected, .multi-select .options .option:hover {
        background: #F3F5F7; }
      .multi-select .options .option .checkbox {
        margin: 0; }
      .multi-select .options .option .label {
        margin: 0 0 0 10px; }

@-webkit-keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.searchable-multi-select {
  position: relative;
  display: flex;
  flex: auto 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0;
  margin: 0; }
  .searchable-multi-select.disabled {
    cursor: not-allowed; }
  .searchable-multi-select.disabled .selected {
    pointer-events: none;
    background: #F3F5F7;
    border-color: #F3F5F7;
    -webkit-animation: lazy 0.8s linear 0s infinite alternate;
            animation: lazy 0.8s linear 0s infinite alternate;
    color: transparent; }
  .searchable-multi-select > .selected {
    position: relative;
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 0.8em;
    height: 100%;
    padding: 0 13px 0 16px;
    align-items: center;
    height: 26px;
    border-radius: 25px;
    border: 0.5px solid #768495;
    box-sizing: border-box;
    outline: 0;
    width: auto; }
    .searchable-multi-select > .selected .label {
      text-indent: 8px;
      font-weight: 200;
      color: #44444D; }
    .searchable-multi-select > .selected .amount {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #768495;
      color: #FFFFFF;
      position: absolute;
      top: 2px;
      left: 12px;
      font-size: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; }
    .searchable-multi-select > .selected > * {
      pointer-events: none; }
    .searchable-multi-select > .selected::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000000;
      margin: 0 0 0 10px; }
  .searchable-multi-select.open .options-container {
    display: block; }
  .searchable-multi-select .options-container {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    background: white;
    box-shadow: 0 2px 8px 0 #D6DAE2;
    border-radius: 5px;
    z-index: 100;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    width: 100%; }
    .searchable-multi-select .options-container > input.search {
      width: 100%;
      height: 26px;
      font-size: 0.8em;
      border: 0.5px solid #768495;
      border-radius: 45px;
      text-indent: 10px;
      color: #44444D;
      font-weight: 200;
      outline: 0;
      box-sizing: border-box;
      background: transparent;
      margin-bottom: 2px; }
    .searchable-multi-select .options-container > ul.options {
      width: auto;
      max-height: 50vh;
      overflow-y: auto;
      position: relative; }
      .searchable-multi-select .options-container > ul.options .option {
        display: flex;
        height: 25px;
        padding: 0 10px;
        align-items: center;
        cursor: pointer;
        color: #44444D;
        font-size: 0.8em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        .searchable-multi-select .options-container > ul.options .option.selected, .searchable-multi-select .options-container > ul.options .option:hover {
          background: #F3F5F7; }
        .searchable-multi-select .options-container > ul.options .option .checkbox {
          margin: 0; }
        .searchable-multi-select .options-container > ul.options .option .label {
          margin: 0 0 0 10px; }

.text {
  font-size: 0.85em;
  color: #44444D; }

@-webkit-keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.select {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0; }
  .select.disabled {
    cursor: not-allowed; }
  .select.disabled .selected {
    pointer-events: none;
    background: #F3F5F7;
    -webkit-animation: lazy 0.8s linear 0s infinite alternate;
            animation: lazy 0.8s linear 0s infinite alternate;
    border-color: #F3F5F7;
    color: transparent; }
  .select > .selected {
    display: flex;
    cursor: pointer;
    font-size: 0.8em;
    height: 100%;
    padding: 0;
    align-items: center;
    height: 26px;
    border-radius: 25px;
    border: 0.5px solid #768495;
    box-sizing: border-box;
    outline: 0;
    width: auto;
    text-indent: 10px;
    font-weight: 200;
    color: #44444D; }
    .select > .selected::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000000;
      margin: 0 12px 0 auto; }
  .select.open .options {
    display: block; }
  .select .options {
    display: none;
    position: absolute;
    left: 0;
    top: 30px;
    background: white;
    box-shadow: 0 2px 8px 0 #D6DAE2;
    border-radius: 5px;
    z-index: 100;
    max-height: 200px;
    width: 100%;
    overflow-y: auto; }
    .select .options .option {
      display: flex;
      height: 25px;
      padding: 0 10px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      color: #44444D;
      font-size: 0.8em;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
      .select .options .option.active, .select .options .option:hover {
        background: #F3F5F7; }

@-webkit-keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.searchable-select {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0; }
  .searchable-select.disabled {
    cursor: not-allowed; }
  .searchable-select.disabled .selected {
    pointer-events: none;
    background: #F3F5F7;
    -webkit-animation: lazy 0.8s linear 0s infinite alternate;
            animation: lazy 0.8s linear 0s infinite alternate;
    border-color: #F3F5F7;
    color: transparent; }
  .searchable-select > .selected {
    display: flex;
    cursor: pointer;
    font-size: 0.8em;
    height: 100%;
    padding: 0;
    align-items: center;
    height: 26px;
    border-radius: 25px;
    border: 0.5px solid #768495;
    box-sizing: border-box;
    outline: 0;
    width: auto;
    text-indent: 10px;
    font-weight: 200;
    color: #44444D; }
    .searchable-select > .selected::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000000;
      margin: 0 12px 0 auto; }
  .searchable-select.open .options-container {
    display: block; }
  .searchable-select .options-container {
    display: none;
    position: absolute;
    left: 0;
    top: 30px;
    background: white;
    box-shadow: 0 2px 8px 0 #D6DAE2;
    border-radius: 5px;
    z-index: 100;
    overflow-y: none;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    width: 100%; }
    .searchable-select .options-container > input.search {
      width: 100%;
      height: 26px;
      font-size: 0.8em;
      border: 0.5px solid #768495;
      border-radius: 45px;
      text-indent: 10px;
      color: #44444D;
      font-weight: 200;
      outline: 0;
      box-sizing: border-box;
      background: transparent;
      margin-bottom: 4px; }
    .searchable-select .options-container > ul.options {
      max-height: 200px;
      width: 100%;
      overflow-y: auto; }
      .searchable-select .options-container > ul.options .option {
        display: flex;
        min-height: 25px !important;
        padding: 0 10px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: #44444D;
        font-size: 0.8em;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        .searchable-select .options-container > ul.options .option.active, .searchable-select .options-container > ul.options .option:hover {
          background: #F3F5F7; }

.total-time {
  font-size: 0.9em; }

.range-date-picker {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0; }
  .range-date-picker .selected {
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 0.8em;
    height: 100%;
    padding: 0;
    align-items: center;
    height: 26px;
    border-radius: 25px;
    border: 0.5px solid #768495;
    box-sizing: border-box;
    outline: 0;
    width: 175px;
    text-indent: 10px;
    font-weight: 200;
    color: #44444D; }
    .range-date-picker .selected::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000000;
      margin: 0 15px 0 auto; }
  .range-date-picker input {
    display: flex;
    cursor: pointer;
    font-size: 0.9em;
    height: 100%;
    padding: 0;
    align-items: center;
    height: 25px;
    border-radius: 25px;
    border: 0.5px solid #768495;
    outline: 0;
    width: auto;
    text-indent: 5px;
    font-weight: 200;
    color: #44444D;
    background: transparent; }
  .range-date-picker .dates {
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center; }
    .range-date-picker .dates .date-input {
      margin: 0 5px; }
    .range-date-picker .dates .dropdown-button {
      width: 20px;
      height: 20px;
      cursor: pointer; }
  .range-date-picker .date-helpers {
    display: none;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    right: 0;
    top: 30px;
    background: white;
    box-shadow: 0 2px 8px 0 #D6DAE2;
    border-radius: 5px;
    z-index: 100; }
    .range-date-picker .date-helpers li {
      display: flex;
      height: 25px;
      padding: 0 10px;
      align-items: center;
      font-size: 0.8em;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer; }
  .range-date-picker.open .dates .dropdown-button {
    transform: rotate(180deg); }
  .range-date-picker.open .date-helpers {
    display: flex; }

@-webkit-keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@keyframes lazy {
  from {
    background-color: #F3F5F7; }
  to {
    background-color: rgba(0, 0, 0, 0.02); } }

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.spinner {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 3px solid transparent;
  border-right: 3px solid #EE4540;
  -webkit-animation: 1s spin infinite;
          animation: 1s spin infinite; }

.panel {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 4px 10px 0 rgba(68, 68, 77, 0.2);
  border-radius: 9px;
  padding: 20px 16px;
  margin: 0 0 20px; }

.modal {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center; }
  .modal .title {
    font-size: 1.7em;
    border-bottom: 0.5px solid #44444D;
    padding: 0 0 5px; }
  .modal .frame {
    background: white;
    width: 500px;
    border-radius: 10px;
    padding: 20px; }
  .modal.draft-report-modal .frame {
    width: 680px; }
    .modal.draft-report-modal .frame .fields {
      align-items: flex-start;
      justify-content: flex-start; }
      .modal.draft-report-modal .frame .fields .field {
        flex-direction: row;
        align-items: center;
        align-self: initial;
        margin: 0 0 10px; }
        .modal.draft-report-modal .frame .fields .field:first-child {
          margin-right: 20px; }
        .modal.draft-report-modal .frame .fields .field > .label {
          min-width: 44px; }
        .modal.draft-report-modal .frame .fields .field > .input.date-input {
          width: auto; }
  .modal.print-modal .frame {
    width: 300px; }
    .modal.print-modal .frame .fields {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start; }
      .modal.print-modal .frame .fields .field {
        flex: 0 1;
        flex-basis: 100px;
        cursor: pointer;
        height: 25px;
        margin: 0;
        margin-bottom: 10px; }
        .modal.print-modal .frame .fields .field input {
          margin-right: 4px; }
        .modal.print-modal .frame .fields .field label {
          -webkit-user-select: none;
              -ms-user-select: none;
                  user-select: none;
          cursor: pointer; }

@charset "UTF-8";
.pagination {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 22px; }
  .pagination .button {
    width: 20px;
    height: 20px;
    color: #44444D;
    background: #FAFAFA;
    padding: 0;
    margin: 0 0 0 5px; }
    .pagination .button.active {
      background: #E73934;
      color: #FFFFFF; }
  .pagination .separator {
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination .separator::before {
      content: '···'; }

.page-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  margin: 0 0 20px;
  align-items: center;
  justify-content: space-between; }
  .page-header .title {
    margin: 0 0 10px; }
  .page-header .session-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8em; }

.delete-confirmation-modal .title {
  font-size: 1.5em;
  border-bottom: 1px solid #44444D;
  padding: 0 0 5px; }

.delete-confirmation-modal .text {
  margin: 0 0 40px; }

.delete-confirmation-modal .button {
  margin: 0 0 0 auto; }

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  color: #EE4540;
  background: #FCECEB;
  height: 27px;
  border-radius: 27px;
  padding: 0 20px;
  margin: 0 0 20px; }

.password-reset-modal .title {
  font-size: 1.5em;
  border-bottom: 1px solid #44444D;
  padding: 0 0 5px; }

.password-reset-modal .text {
  margin: 0 0 40px; }

.password-reset-modal .button {
  margin: 0 0 0 auto; }

.password-reset-modal .title {
  font-size: 1.5em;
  border-bottom: 1px solid #44444D;
  padding: 0 0 5px; }

.password-reset-modal .text {
  margin: 0 0 40px; }

.password-reset-modal .button {
  margin: 0 0 0 auto; }

.menu-item {
  display: flex;
  height: 30px;
  align-items: center;
  background: transparent;
  margin: 0 0 5px 20px;
  border-radius: 30px 0 0 30px;
  padding: 0 0 0 30px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 0.8em; }
  .menu-item .icon {
    margin: 0 23px 0 0; }

.menu-item.active {
  background: #768495; }

.locale-selector {
  display: flex;
  margin: auto 0 0;
  justify-content: center; }
  .locale-selector .locale {
    color: #D6DAE2;
    cursor: pointer;
    font-size: 0.8em;
    margin: 0 7px; }
    .locale-selector .locale.active {
      color: #FFFFFF;
      font-weight: 500; }

.table {
  display: flex;
  flex-direction: column; }
  .table .row {
    display: flex;
    flex-direction: row;
    height: 40px; }
    .table .row.header {
      background: #D6DAE2;
      height: 35px; }
      .table .row.header .column {
        color: #44444D;
        font-size: 1em; }
    .table .row .column {
      display: flex;
      flex: 1 1;
      align-items: center;
      color: black;
      border-bottom: 1px solid #D6DAE2;
      padding: 0 0 0 15px;
      font-size: 0.9em;
      overflow: hidden;
      width: 50px; }
      .table .row .column p {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0; }
      .table .row .column.double-flex {
        flex: 2 1; }
      .table .row .column.triple-flex {
        flex: 4 1; }
      .table .row .column.no-flex {
        flex: 0 1;
        flex-basis: 75px;
        padding: 0 15px; }

.table-actions {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .table-actions .separator {
    margin: 0 10px;
    width: 1px;
    height: 20px;
    background: #D6DAE2; }
  .table-actions .icon {
    cursor: pointer; }

.list {
  width: 100%; }
  .list header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .list header p {
      margin: 0 10px 10px 0;
      flex: 1 1; }
      .list header p:last-of-type {
        flex: 0 1;
        flex-basis: 12px; }
  .list ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0; }
    .list ul li {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px; }
      .list ul li > div {
        flex: 1 1;
        margin-right: 10px; }
        .list ul li > div:last-of-type {
          flex: 0 1; }
      .list ul li .input,
      .list ul li .searchable-select {
        width: 100% !important;
        flex: 1 1;
        margin: 0; }
      .list ul li .input.date {
        width: auto !important; }
      .list ul li input:invalid {
        border-color: #E73934; }
    .list ul > .icon {
      margin-top: 5px;
      width: 26px;
      height: 26px; }
    .list ul .icon {
      cursor: pointer; }
  .list.time-records header p:first-child,
  .list.time-records header p:nth-child(2) {
    flex: 2 1; }
  .list.time-records header p:nth-child(3),
  .list.time-records header p:nth-child(4) {
    flex: 0 1;
    flex-basis: 75px; }
  .list.time-records ul li > div:first-child,
  .list.time-records ul li > div:nth-child(2) {
    flex: 2 1; }
  .list.time-records ul li > div:nth-child(3),
  .list.time-records ul li > div:nth-child(4) {
    flex: 0 1;
    flex-basis: 75px; }
  .list.material-records header p:nth-child(2) {
    flex: 0 1;
    text-align: center; }
  .list.material-records ul li > div:nth-child(2) {
    flex: 0 1;
    flex-basis: 55px; }

.tabs nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px; }
  .tabs nav section {
    height: 30px;
    padding: 0 20px 0 0;
    font-size: 0.9em;
    cursor: pointer; }
    .tabs nav section.active {
      color: #E73934;
      border-bottom: 1px solid #E73934;
      font-weight: bold; }

.menu {
  display: flex;
  flex-direction: column;
  flex: auto 0;
  width: 185px;
  background: #44444D;
  padding: 30px 0;
  flex-shrink: 0; }
  .menu .logo {
    width: 132px;
    margin: 0 auto 72px; }

.version {
  display: flex;
  margin: 1rem 0 0;
  justify-content: center;
  color: #FFFFFF; }

.page.not-found .content {
  align-items: center;
  justify-content: center; }

.page.sign-in {
  flex-direction: row; }
  .page.sign-in .title {
    font-size: 2em;
    margin: 0 0 27px; }
  .page.sign-in .logo {
    width: 65%; }
  .page.sign-in .input {
    height: 45px;
    text-indent: 23px;
    border-color: #768495; }
    .page.sign-in .input.email {
      margin: 0 0 11px; }
    .page.sign-in .input.password {
      margin: 0 0 27px; }
  .page.sign-in .button {
    box-shadow: none;
    margin: 0 0 10px; }

.page.forgot-password {
  flex-direction: row; }
  .page.forgot-password .title {
    font-size: 2em;
    margin: 0 0 27px; }
  .page.forgot-password .logo {
    width: 275px; }
  .page.forgot-password .input {
    height: 45px;
    text-indent: 23px;
    border-color: #768495; }
    .page.forgot-password .input.email {
      margin: 0 0 27px; }
  .page.forgot-password .button {
    box-shadow: none;
    margin: 0 0 10px; }

.reset-password .title {
  font-size: 2em;
  margin: 0 0 27px; }

.reset-password .logo {
  width: 275px; }

.reset-password .input {
  height: 45px;
  text-indent: 23px;
  border-color: #768495; }
  .reset-password .input.password {
    margin: 0 0 11px; }
  .reset-password .input.password-confirmation {
    margin: 0 0 27px; }

.reset-password .button {
  box-shadow: none;
  margin: 0 0 10px; }

@font-face {
  font-family: 'arciform';
  src: url("/assets/fonts/arciform.otf") format("woff"), url("/assets/fonts/arciform.otf") format("truetype"); }

@font-face {
  font-family: 'Avenir Next LT Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/AvenirNextLTPro-Regular.woff") format("woff"); }

body {
  margin: 0;
  font-family: 'Avenir Next LT Pro Regular', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px; }
  body.no-scroll {
    overflow: hidden; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

main .page {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  min-height: 100vh;
  min-width: 800px;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden; }
  main .page > .content {
    display: flex;
    flex: 1 1;
    padding: 26px 32px;
    flex-direction: column;
    background: linear-gradient(180deg, #FFFFFF, #E9E9E9);
    overflow-y: auto; }
    main .page > .content .spinner {
      position: relative;
      width: 30px;
      height: 30px;
      margin: 15px auto 20px; }
    main .page > .content .button {
      align-self: flex-start; }
      main .page > .content .button.new-button {
        margin: 0 10px 0; }
      main .page > .content .button.inline-new-button {
        margin: 0 10px 0; }
    main .page > .content > .filters {
      display: flex;
      flex-direction: row;
      margin: 0 0 20px;
      flex-wrap: wrap; }
      main .page > .content > .filters .multi-select, main .page > .content > .filters .select, main .page > .content > .filters .searchable-select, main .page > .content > .filters .searchable-multi-select, main .page > .content > .filters .range-date-picker, main .page > .content > .filters .input {
        margin: 0 10px 0 0; }
      main .page > .content > .filters .input {
        width: 150px;
        border-color: #768495;
        font-size: 0.8em; }
      main .page > .content > .filters .button.push {
        margin: 0 10px 0 auto; }
      main .page > .content > .filters .button.middle {
        margin-right: 10px; }
  main .page .side {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: center; }
    main .page .side:first-child {
      background: #44444D;
      justify-content: center;
      align-items: center; }
    main .page .side > .content {
      display: flex;
      flex: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      main .page .side > .content.no-flex {
        flex: 0 1; }
      main .page .side > .content .locale-selector {
        flex-direction: column;
        margin: 0 40px 0 0;
        align-self: flex-end; }
        main .page .side > .content .locale-selector .locale {
          margin: 4px 0; }
          main .page .side > .content .locale-selector .locale.active {
            color: #44444D; }
  main .page footer {
    display: flex;
    flex-direction: row; }
    main .page footer .field {
      margin: 0;
      padding-bottom: 22px; }
  main .page .modal .fields {
    margin: 50px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  main .page .modal .input, main .page .modal .select, main .page .modal .searchable-select {
    width: 310px; }
  main .page .modal .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    main .page .modal .buttons .button {
      margin: 0 0 0 10px; }

