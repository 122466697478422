@import '../../styles/colors.scss';
@import '../../styles/animations.scss';

.searchable-select {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .selected {
    pointer-events: none;
    background: $color5;
    animation: lazy 0.8s linear 0s infinite alternate;
    border-color: $color5;
    color: transparent;
  }
  
  > .selected {
    display: flex;
    cursor: pointer;
    font-size: 0.8em;
    height: 100%;
    padding: 0;
    align-items: center;
    height: 26px;
    border-radius: 25px;
    border: 0.5px solid $color4;
    box-sizing: border-box;
    outline: 0;
    width: auto;
    text-indent: 10px;
    font-weight: 200;
    color: $color3;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $color0;
      margin: 0 12px 0 auto;
    }
  }

  &.open .options-container {
    display: block;
  }

  .options-container {
    display: none;
    position: absolute;
    left: 0;
    top: 30px;
    background: white;
    box-shadow: 0 2px 8px 0 #D6DAE2;
    border-radius: 5px;
    z-index: 100;
    overflow-y: none;
    min-width: max-content;
    width: 100%;

    > input.search {
      width: 100%;
      height: 26px;
      font-size: 0.8em;
      border: 0.5px solid #768495;
      border-radius: 45px;
      text-indent: 10px;
      color: #44444D;
      font-weight: 200;
      outline: 0;
      box-sizing: border-box;
      background: transparent;
      margin-bottom: 4px;
    }
  
    > ul.options {
      max-height: 200px;
      width: 100%;
      overflow-y: auto;
  
      .option {
        display: flex;
        min-height: 25px !important;
        padding: 0 10px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: $color3;
        font-size: 0.8em;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
  
        &.active, &:hover {
          background: $color5;
        }
      }
    }
  }
}
