@import '../../styles/colors.scss';

.locale-selector {
  display: flex;
  margin: auto 0 0;
  justify-content: center;

  .locale {
    color: $color6;
    cursor: pointer;
    font-size: 0.8em;
    margin: 0 7px;

    &.active {
      color: $color1;
      font-weight: 500;
    }
  }
}
