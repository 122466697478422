@import '../../styles/colors.scss';

.toggle {
  display: flex;
  position: relative;
  width: 25px;
  height: 12px;
  background: $color1;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid $color8;

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    border-radius: 9px;
    background: $color8;
    top: 1px;
    bottom: 1px;
    margin: auto;
    left: 1px;
  }

  &.active::after {
    left: auto;
    right: 1px;
    background: $color6;
  }
}
