@import '../../styles/colors.scss';

.panel {
  display: flex;
  flex-direction: column;
  background: $color1;
  box-shadow: 0 4px 10px 0 rgba(68,68,77,0.2);
  border-radius: 9px;
  padding: 20px 16px;
  margin: 0 0 20px;
}