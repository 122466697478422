@import '../../styles/colors.scss';

.password-reset-modal {
  .title {
    font-size: 1.5em;
    border-bottom: 1px solid $color3;
    padding: 0 0 5px;
  }

  .text {
    margin: 0 0 40px;
  }

  .button {
    margin: 0 0 0 auto;
  }
}
