@import '../../styles/colors.scss';

.table {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    height: 40px;

    &.header {
      background: $color6;
      height: 35px;

      .column {
        color: $color3;
        font-size: 1em;
      }
    }

    .column {
      display: flex;
      flex: 1;
      align-items: center;
      color: black;
      border-bottom: 1px solid $color6;
      padding: 0 0 0 15px;
      font-size: 0.9em;
      overflow: hidden;
      width: 50px;
      
      p {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }

      &.double-flex {
        flex: 2 1;
      }
      &.triple-flex {
        flex: 4 1;
      }

      &.no-flex {
        flex: 0 1;
        flex-basis: 75px;
        padding: 0 15px;
      }
    }
  }
}