@import '../../styles/colors.scss';
@import '../../styles/animations.scss';

.spinner {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 3px solid transparent;
  border-right: 3px solid $color2;
  animation: 1s spin infinite;
}
