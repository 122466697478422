@import '../../styles/colors.scss';

.menu-item {
  display: flex;
  height: 30px;
  align-items: center;
  background: transparent;
  margin: 0 0 5px 20px;
  border-radius: 30px 0 0 30px;
  padding: 0 0 0 30px;
  color: $color1;
  cursor: pointer;
  font-size: 0.8em;

  .icon {
    margin: 0 23px 0 0;
  }
}

.menu-item.active {
  background: $color4;
}
