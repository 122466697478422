@import '../../styles/colors.scss';

.range-date-picker {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;

  .selected {
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 0.8em;
    height: 100%;
    padding: 0;
    align-items: center;
    height: 26px;
    border-radius: 25px;
    border: 0.5px solid $color4;
    box-sizing: border-box;
    outline: 0;
    width: 175px;
    text-indent: 10px;
    font-weight: 200;
    color: $color3;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $color0;
      margin: 0 15px 0 auto;
    }
  }

  input {
    display: flex;
    cursor: pointer;
    font-size: 0.9em;
    height: 100%;
    padding: 0;
    align-items: center;
    height: 25px;
    border-radius: 25px;
    border: 0.5px solid $color4;
    outline: 0;
    width: auto;
    text-indent: 5px;
    font-weight: 200;
    color: $color3;
    background: transparent;
  }

  .dates {
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    .date-input {
      margin: 0 5px;
    }

    .dropdown-button {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .date-helpers {
    display: none;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    right: 0;
    top: 30px;
    background: white;
    box-shadow: 0 2px 8px 0 #D6DAE2;
    border-radius: 5px;
    z-index: 100;

    li {
      display: flex;
      height: 25px;
      padding: 0 10px;
      align-items: center;
      font-size: 0.8em;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  &.open {
    .dates {
      .dropdown-button {
        transform: rotate(180deg);
      }
    }

    .date-helpers {
      display: flex;
    }
  }
}