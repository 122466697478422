@import './colors.scss';

@font-face {
  font-family: 'arciform';
  src: url('/assets/fonts/arciform.otf') format('woff'),
  url('/assets/fonts/arciform.otf') format('truetype');
}

@font-face {
  font-family: 'Avenir Next LT Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Avenir Next LT Pro Regular', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;

  &.no-scroll {
    overflow: hidden;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  .page {
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 100vh;
    min-width: 800px;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;

    > .content {
      display: flex;
      flex: 1;
      padding: 26px 32px;
      flex-direction: column;
      background: linear-gradient(180deg, #FFFFFF, #E9E9E9);
      overflow-y: auto;

      .spinner {
        position: relative;
        width: 30px;
        height: 30px;
        margin: 15px auto 20px;
      }

      .button {
        align-self: flex-start;

        &.new-button {
          margin: 0 10px 0;
        }

        &.inline-new-button {
          margin: 0 10px 0;
        }
      }

      > .filters {
        display: flex;
        flex-direction: row;
        margin: 0 0 20px;
        flex-wrap: wrap;

        .multi-select, .select, .searchable-select, .searchable-multi-select, .range-date-picker, .input {
          margin: 0 10px 0 0;
        }
    
        .input {
          width: 150px;
          border-color: $color4;
          font-size: 0.8em;
        }

        .button.push {
          margin: 0 10px 0 auto;
        }

        .button.middle {
          margin-right: 10px;
        }
      }
    }

    .side {
      display: flex;
      flex: 1 1;
      flex-direction: row;
      justify-content: center;

      &:first-child {
        background: $color3;
        justify-content: center;
        align-items: center;
      }

      > .content {
        display: flex;
        flex: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.no-flex {
          flex: 0;
        }

        .locale-selector {
          flex-direction: column;
          margin: 0 40px 0 0;
          align-self: flex-end;

          .locale {
            margin: 4px 0;
            &.active {
              color: $color3;
            }
          }
        }
      }
    }

    footer {
      display: flex;
      flex-direction: row;

      .field {
        margin: 0;
        padding-bottom: 22px;
      }
    }

    .modal {
      .fields {
        margin: 50px 0 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .input, .select, .searchable-select {
        width: 310px;
      }
    
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    
        .button {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}
