@import '../../styles/colors.scss';

.page-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  margin: 0 0 20px;
  align-items: center;
  justify-content: space-between;

  .title {
    margin: 0 0 10px;
  }

  .session-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8em;
  }
}
